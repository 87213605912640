/* eslint-disable */
import { DOMESTIC_FORMWORK, FORMWORK_NAMES, INTERNATIONAL_FORMWORK } from '@/utils/constant'

export default {
  computed: {
    isDomestic() {
      return this.formworkType == DOMESTIC_FORMWORK
    },
    formworkType() {
      return this.$route.query.category || INTERNATIONAL_FORMWORK
    },
    formworkName() {
      return FORMWORK_NAMES[this.formworkType]
    }
  }
}